<template>
    <div
        class="vehicl-map"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <div class="map-con">
            <div class="functionalArea" :class="{'isLargeScreen': isLargeScreen}">
                <div class="station-list el_left" v-if="stationList.length>1">
                    <el-select
                        v-model="currentStationId"
                        placeholder="请选择"
                        @change="changeStation"
                    >
                        <el-option
                            v-for="item in stationList"
                            :key="item.station_id"
                            :label="item.name"
                            :value="item.station_id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <el-input
                    v-if="iconStatus"
                    class="search-box el_left bm-search-box"
                    v-model="keyword"
                ></el-input>

                <div v-if="!iconStatus" class="search-car-div el_left">
                    <el-input placeholder="工程名称/工程地址/车号/车牌号" v-model="searchInfo"></el-input>
                    <!-- <i @click="getLocation" class="iconfont iconsousuo1"></i> -->
                    <i @click="getLocation">搜索</i>
                </div>
                <div class="checkout-icon el_left">
                    <el-tooltip
                        v-show="iconStatus"
                        class="item"
                        effect="dark"
                        content="去检索工程与车辆信息"
                        placement="bottom"
                    >
                        <i @click="checkoutIcon" class="el-icon-map-location"></i>
                    </el-tooltip>
                    <el-tooltip
                        v-show="!iconStatus"
                        class="item"
                        effect="dark"
                        content="去检索地理位置信息"
                        placement="bottom"
                    >
                        <i v-show="!iconStatus" @click="checkoutIcon" class="el-icon-truck"></i>
                    </el-tooltip>
                </div>
                <div class="setting-top-box el_left" @click="setCarTime">
                    <span class="iconfont">&#xe672;</span>
                    <span>设置</span>
                </div>
                <!-- <div class="antline-btn el_left" @click="showAntLine">
                    <span class="iconfont icontiaodu"></span>
                    <span>蚂蚁线</span>
                </div> -->
                <!-- <div class="antline-btn el_left" @click="sdcfFn">
                    <span class="iconfont icontiaodu"></span>
                    <span>手动触发</span>
                </div> -->
            </div>
            <!-- 总设置 -->
            <div class="setting-window" v-show="settingWindowStatus">
                <div class="setting-window-top">
                    <p>定位频率设置</p>
                    <span class="triangle-up"></span>
                </div>
                <div class="setting-input">
                    <input v-model="setWholeTime" placeholder="请输入" type="text">
                    <span>秒</span>
                </div>
                <div class="setting-btn">
                    <el-button type="primary" size="mini" @click="setCarInterval(0)">
                        确定
                    </el-button>
                    <el-button plain size="mini" @click="settingWindowStatus = false">
                        取消
                    </el-button>
                </div>
            </div>
            <!-- 单独车辆设置 -->
            <div class="setting-window setting-window-two" v-show="settingWindowStatusTwo">
                <div class="setting-window-top">
                    <p>定位频率设置</p>
                    <span class="triangle-up"></span>
                </div>
                <div class="setting-input">
                    <input v-model="setWholeTime" placeholder="请输入" type="text">
                    <span>秒</span>
                </div>
                <div class="setting-btn">
                    <el-button @click="setCarInterval(0)" type="primary" size="mini">
                        确定
                    </el-button>
                    <el-button plain size="mini" @click="settingWindowStatusTwo = false">
                        取消
                    </el-button>
                </div>
            </div>
            <baidu-map
                :center="mapCenter"
                :zoom="zoom"
                class="amap-demo"
                @ready="handleReady"
            >
                <bm-local-search
                    :keyword="keyword"
                    :location="searchOption"
                    :searchcomplete="onSearchResult"
                    :auto-viewport="true"
                    class="local-search"
                ></bm-local-search>
                <!-- 车辆点标记 -->
                <bm-marker
                    v-for="(marker, index) in markers"
                    :key="index"
                    :position="marker.position"
                    :icon="{url: marker.icon, size: marker.size}"
                    :rotation="marker.rotation"
                    @click="switchWindow(index, marker, marker.position)"
                >
                    <bm-label
                        :content="marker.plate_small_number"
                        :offset="marker.offset"
                    ></bm-label>
                </bm-marker>
                <!-- 工程地址 -->
                <bm-marker
                    v-for="(marker, index) in projectsAddressMarkers"
                    :key="`address${index}`"
                    :position="marker.position"
                    :icon="{url: marker.icon, size: marker.size}"
                    @click="showPojectAddressInfoWindow(marker)"
                >
                    <bm-label
                        :content="marker.project_name"
                        :offset="marker.offset"
                    ></bm-label>
                </bm-marker>
                <!-- 工地圆圈 -->
                <bm-circle
                    :key="`projectCircle_${index}`"
                    v-for="(circle, index) in projectCircles"
                    :center="circle.center"
                    :radius="circle.radius"
                    :stroke-color="circle.strokeColor"
                    :stroke-opacity="circle.strokeOpacity"
                    :stroke-weight="circle.strokeWeight"
                    :fill-color="circle.fillColor"
                    :fill-opacity="circle.fillOpacity"
                    :stroke-style="circle.strokeStyle"
                ></bm-circle>
                <!-- 场站圆圈 -->
                <bm-circle
                    :key="`stationCircle_${index}`"
                    v-for="(circle, index) in stationCircles"
                    :center="circle.center"
                    :radius="circle.radius"
                    :stroke-color="circle.strokeColor"
                    :stroke-opacity="circle.strokeOpacity"
                    :stroke-weight="circle.strokeWeight"
                    :fill-color="circle.fillColor"
                    :fill-opacity="circle.fillOpacity"
                    :stroke-style="circle.strokeStyle"
                ></bm-circle>
                <!-- 信息窗体 -->
                <bm-info-window
                    :position="windows.position"
                    :show="windows.visible"
                    :offset="windows.offset"
                    :close-on-click="true"
                    class="car-info-window"
                >
                    <div class="car-info" v-if="carInfo">
                        <div class="icon iconfont iconshangjiantou1"></div>
                        <div class="info-header">
                            <div>
                                <span class="iconfont">&#xe6a3;</span>
                                <span class="plateNumber">{{ carInfo.plate_number }}</span>
                                <span class="trumpet">{{ carInfo.plate_small_number }}</span>
                                <span
                                    v-if="[6, 7, 73].indexOf(carInfo.status) > -1"
                                    class="transportation-status transportation-status-green"
                                >运输中</span>
                                <span
                                    v-else-if="[77, 8].indexOf(carInfo.status) > -1"
                                    class="transportation-status transportation-status-yellow"
                                >返场中</span>
                                <span v-else class="transportation-status transportation-status-red">未运输</span>
                            </div>
                            <div>
                                <span @click="setWinodShow" class="iconfont setting-car-window">&#xe672;</span>
                            </div>
                        </div>
                        <div class="info-detailed">
                            <template v-if="[6, 7, 73, 77, 8].indexOf(carInfo.status) > -1">
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe6b7;</span>
                                        <span>司机姓名：{{ carInfo.driver_name }}</span>
                                    </p>
                                    <p style="text-align: right;">
                                        <span class="iconfont">&#xe6c7;</span>
                                        <span>电话：{{ carInfo.driver_phone }}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe693;</span>
                                        <span>发货单号：{{ carInfo.pcb_number }}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe67b;</span>
                                        <span>工地名称：{{ carInfo.project_name }}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe646;</span>
                                        <span>今日运输次数：{{ carInfo.cur_count }}</span>
                                    </p>
                                </div>
                                <template v-if="[77, 8].indexOf(carInfo.status) > -1">
                                    <div>
                                        <p>
                                            <span class="iconfont">&#xe69f;</span>
                                            <span>车辆距场站（KM）：{{ carInfo.to_station_distance }}</span>
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            <span class="iconfont">&#xe667;</span>
                                            <span>预计到达场站时间：{{ SecondToDate(carInfo.to_station_time) }}</span>
                                        </p>
                                    </div>
                                </template>
                                <template v-else>
                                    <template v-if="carInfo.status == 6">
                                        <div>
                                            <p>
                                                <span class="iconfont">&#xe69f;</span>
                                                <span>车辆距工地（KM）：{{ carInfo.to_project_distance }}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                <span class="iconfont">&#xe667;</span>
                                                <span>预计到达工地时间：{{ SecondToDate(carInfo.to_project_time) }}</span>
                                            </p>
                                        </div>
                                    </template>
                                    <div v-else>
                                        <p>
                                            <span class="iconfont">&#xe667;</span>
                                            <span>到达工地时间：{{ carInfo.arrival_time }}</span>
                                        </p>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe6b7;</span>
                                        <span>司机姓名：{{ carInfo.driver_name }}</span>
                                    </p>
                                    <p style="text-align: right;">
                                        <span class="iconfont">&#xe6c7;</span>
                                        <span>电话：{{ carInfo.driver_phone }}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe693;</span>
                                        <span>发货单号：</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe67b;</span>
                                        <span>工地名称：</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe646;</span>
                                        <span>今日运输次数：{{ carInfo.cur_count }}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe69f;</span>
                                        <span>车辆距工地（KM）：</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe667;</span>
                                        <span>到达工地时间：</span>
                                    </p>
                                </div>
                            </template>
                        </div>
                    </div>
                </bm-info-window>
                <!-- 工程地址信息窗体 -->
                <bm-info-window
                    :position="addressWindow.position"
                    :show="addressWindow.visible"
                    :offset="addressWindow.offset"
                    :events="addressWindow.events"
                    :close-when-click-map="true"
                >
                    <projectAddressWindow ref="projectAddressInfoWindow"></projectAddressWindow>
                </bm-info-window>
            </baidu-map>
            <!-- 右侧功能面板 -->
            <actionsPanel
                v-if="!isLargeScreen"
                :projects-statistics="projectsStatistics"
                :car-statistics="carStatistics"
                :projects-address="projectsAddress"
                @projectsClick="handleProjectsClick"
                @carClick="handleCarClick"
                :current-station-id="currentStationId"
            ></actionsPanel>
        </div>
    </div>
</template>

<script>
// /* eslint-disable */
import { eventBus } from '@/common/eventBus.ts';
import util from '@/utils/util';
import actionsPanel from './actionsPanel';
import projectAddressWindow from './projectAddressWindow';
import Vue from 'vue';
import BaiduMap from 'vue-baidu-map';
import GeoUtils from '@/plugins/BMapLibGeoUtils/index';
Vue.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: '7FR14186sYk3MqjP9ggWxuHx1AQkRzZX',
});
export default {
    // actionsPanel, projectAddressWindow
    components: { actionsPanel, projectAddressWindow },
    props: {
        isLargeScreen: {
            type: [Boolean],
            required: false, // 是否必传
        },
        pageType: {
            type: [Array, Object, String ],
            required: false, // 是否必传
        },
    },
    data() {
        return {
            iconStatus: true,
            searchInfo: '',
            // 地图相关
            // amapManager,
            BMap: {},
            accuratePosition: {},
            zoom: 3,
            mapCenter: { lng: 0, lat: 0 },
            lng: 0,
            lat: 0,
            loaded: false,
            settingWindowStatus: false,
            settingWindowStatusTwo: false,
            setWholeTime: 0,
            searchOption: '全国',
            keyword: '',
            saitchData: {},
            projectsAddressMarkers: [],
            projectsStatistics: {
                all: 0,
                notWork: 0,
                notFinished: 0,
                trans: 0,
            },
            // 工程地址统计
            carStatistics: {
                all: 0,
                notWork: 0,
                trans: 0,
                back: 0,
            },
            // 车辆统计
            windows: {
                position: { lng: 0, lat: 0 },
                visible: false,
                offset: { width: -10, height: -20 },
                events: {
                },
            },
            // 工程地址信息窗体
            addressWindow: {
                position: { lng: 0, lat: 0 },
                visible: false,
                offset: { width: -10, height: -20 },
                events: {
                },
            },
            switchData: {},
            // groundimages: [
            //     {
            // eslint-disable-next-line max-len
            //         url: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1590989238989&di=c6233e26d355c10a8f3d2f26e9ce5657&imgtype=0&src=http%3A%2F%2Fbpic.588ku.com%2Felement_pic%2F01%2F47%2F31%2F885743e52ebb6e4.jpg',
            //         bounds: [[121.5273285, 31.21515044], [122.9273285, 32.31515044]],
            //         clickable: true,
            //         events: {
            //             click: () => {
            //                 alert('aaa');
            //             },
            //         },
            //     },
            // ],
            // 点标记
            markers: [],
            markersMapping: {}, // 值映射
            plugin: [{
                pName: 'Geolocation',
                enableHighAccuracy: true, // 是否使用高精度定位，默认:true
                timeout: 10, // 超过10秒后停止定位，默认：无穷大
                zoomToAccuracy: true,
            }],
            carPosition: {},
            carInfo: {},
            carStatus: {},
            // 场站数据
            stationData: {},
            activeProject: 1, // 选中工地
            oldProjects: [],
            activeCar: 1,
            oldCars: [],
            // 场站
            stationList: [],
            // 当前场站Index
            activeStation: 0,
            // 当前场站ID
            currentStationId: '',
            // 当天有任务的工地数据
            fenceData: [],
            // 地图对象
            mapObj: {},
            // 工地围栏
            projectEchoPolygon: [],
            // 工地圆圈
            projectCircles: [],
            // 场站围栏
            stationEchoPolygon: [],
            // 场站圆圈
            stationCircles: [],
            timer: 0,
            // 加载状态
            loading: false,
            // 是否拉取所有场站
            allStation: '',
            // 工地源数据
            projectsAddress: [],
            // 车辆源数据
            carsList: [],
            // 车辆刷新 timer
            refreshCarTimer: null,
            // signalr groupName
            groupName: '',
        };
    },
    beforeDestroy() {
        // 清除事件总线eventBus， 不手动清除，它是一直会存在的，就会反复进入
        eventBus.$off('Monitoring');
        // eventBus.$off('CoordinateMessage');
        eventBus.$emit('signalrInvoke', 'RemoveFromGroup', this.groupName);
        clearInterval(this.refreshCarTimer);
    },
    watch: {
        pageType(newVal) {
            if (newVal && newVal === 'all') {
                this.getAllStation();
            }
        },
        carPosition: {
            deep: true,
            immediate: true,
            handler: function (val) {
                // 通过 映射找到 当前车辆
                const item = this.markersMapping[val.car_license];
                if (item) {
                    const obj = this.bd_encrypt(val.longitude, val.latitude);
                    item.position = { lng: obj.bd_lng, lat: obj.bd_lat };
                    let direction;
                    if (val.direction) {
                        direction = parseInt(Number(val.direction), 0);
                    } else {
                        direction = 0;
                    }
                    item.direction = direction;
                    // const parseIcon = this.getIcon(val);
                    // item.icon = parseIcon.icon;
                    // item.size = parseIcon.size;
                    // item.offset = parseIcon.offset;
                    let icon = '';
                    // const size = { width: 22, height: 38 };
                    const res = {
                        icon: '',
                        size: { width: 22, height: 38 },
                        offset: { width: 5, height: 40 },
                    };
                    const pt = new this.BMap.Point(item.position.lng, item.position.lat);
                    const isInarr = GeoUtils.isPointInPolygon(pt, this.stationEchoPolygon[0], this.BMap);
                    if ([6].indexOf(item.status) > -1) {
                        if (isInarr) {
                            icon = require('../../../assets/images/productionManagement/blue.png');
                            res.size = { width: 22, height: 38 };
                            res.offset = { width: 5, height: 40 };
                        } else {
                            icon = require('../../../assets/images/productionManagement/blue.gif');
                            res.size = { width: 28, height: 45 };
                            res.offset = { width: 5, height: 40 };
                        }
                    } else if ([7, 73].indexOf(item.status) > -1) {
                        if (isInarr) {
                            icon = require('../../../assets/images/productionManagement/green.png');
                            res.size = { width: 22, height: 38 };
                            res.offset = { width: 5, height: 40 };
                        } else {
                            icon = require('../../../assets/images/productionManagement/green.gif');
                            res.size = { width: 28, height: 45 };
                            res.offset = { width: 5, height: 40 };
                        }
                    } else if ([77, 8].indexOf(item.status) > -1) {
                        if (isInarr) {
                            icon = require('../../../assets/images/productionManagement/yellow.png');
                            res.size = { width: 22, height: 38 };
                            res.offset = { width: 5, height: 40 };
                        } else {
                            icon = require('../../../assets/images/productionManagement/yellow.gif');
                            res.size = { width: 28, height: 45 };
                            res.offset = { width: 5, height: 40 };
                        }
                    } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                        if (isInarr) {
                            icon = require('../../../assets/images/productionManagement/red.png');
                            res.size = { width: 22, height: 38 };
                            res.offset = { width: 5, height: 40 };
                        } else {
                            icon = require('../../../assets/images/productionManagement/red.gif');
                            res.size = { width: 28, height: 45 };
                            res.offset = { width: 5, height: 40 };
                        }

                    }
                    res.icon = icon;
                    item.icon = res.icon;
                    item.size = res.size;
                    item.offset = res.offset;
                }
                this.$forceUpdate();
            },
        },
        carStatus: {
            deep: true,
            immediate: true,
            handler: function (val) {
                let is_change = false;
                // 通过映射找到当前车辆
                const item = this.markersMapping[val.car_license];
                if (item && item.status !== val.car_status) {
                    let car_type = '';
                    const car_icon = '';
                    item.status = val.car_status;
                    let icon = '';
                    // const size = { width: 22, height: 38 };
                    const res = {
                        icon: '',
                        size: { width: 22, height: 38 },
                        offset: { width: 5, height: 40 },
                    };
                    const pt = new this.BMap.Point(item.position.lng, item.position.lat);
                    const isInarr = GeoUtils.isPointInPolygon(pt, this.stationEchoPolygon[0], this.BMap);
                    if ([6].indexOf(item.status) > -1) {
                        if (isInarr) {
                            icon = require('../../../assets/images/productionManagement/blue.png');
                            res.size = { width: 22, height: 38 };
                            res.offset = { width: 5, height: 40 };
                        } else {
                            icon = require('../../../assets/images/productionManagement/blue.gif');
                            res.size = { width: 28, height: 45 };
                            res.offset = { width: 5, height: 40 };
                        }
                    } else if ([7, 73].indexOf(item.status) > -1) {
                        if (isInarr) {
                            icon = require('../../../assets/images/productionManagement/green.png');
                            res.size = { width: 22, height: 38 };
                            res.offset = { width: 5, height: 40 };
                        } else {
                            icon = require('../../../assets/images/productionManagement/green.gif');
                            res.size = { width: 28, height: 45 };
                            res.offset = { width: 5, height: 40 };
                        }
                    } else if ([77, 8].indexOf(item.status) > -1) {
                        if (isInarr) {
                            icon = require('../../../assets/images/productionManagement/yellow.png');
                            res.size = { width: 22, height: 38 };
                            res.offset = { width: 5, height: 40 };
                        } else {
                            icon = require('../../../assets/images/productionManagement/yellow.gif');
                            res.size = { width: 28, height: 45 };
                            res.offset = { width: 5, height: 40 };
                        }
                    } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                        if (isInarr) {
                            icon = require('../../../assets/images/productionManagement/red.png');
                            res.size = { width: 22, height: 38 };
                            res.offset = { width: 5, height: 40 };
                        } else {
                            icon = require('../../../assets/images/productionManagement/red.gif');
                            res.size = { width: 28, height: 45 };
                            res.offset = { width: 5, height: 40 };
                        }

                    }
                    res.icon = icon;
                    item.icon = res.icon;
                    item.size = res.size;
                    item.offset = res.offset;
                    if ([6].indexOf(item.status) > -1) {
                        car_type = 'trans';
                    } else if ([7, 73].indexOf(item.status) > -1) {
                        car_type = 'onSite';
                    } else if ([77, 8].indexOf(item.status) > -1) {
                        car_type = 'back';
                    } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                        car_type = 'notWork';
                    }
                    if (car_type !== '' && item.type !== car_type) {
                        is_change = true;
                        item.icon = car_icon;
                        item.type = car_type;
                        this.$forceUpdate();
                    }
                }
                if (is_change) {
                    this.oldCars = JSON.parse(JSON.stringify(this.markers));
                    let notWork = 0;
                    let trans = 0;
                    let back = 0;
                    let onSite = 0;
                    this.markers.map(item => {
                        if ([6].indexOf(item.status) > -1) {
                            trans += 1;
                        } else if ([7, 73].indexOf(item.status) > -1) {
                            onSite += 1;
                        } else if ([77, 8].indexOf(item.status) > -1) {
                            back += 1;
                        } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                            notWork += 1;
                        }
                    });
                    this.carStatistics.all = notWork + trans + back + onSite;
                    this.carStatistics.notWork = notWork;
                    this.carStatistics.trans = trans;
                    this.carStatistics.back = back;
                    this.carStatistics.onSite = onSite;
                }
            },
        },
    },
    mounted() {
        eventBus.$on('Monitoring', data => {
            this.carStatus = JSON.parse(data);
        });
        // eventBus.$on('CoordinateMessage', data => {
        //     if (this.validData(data.tenant_id, data.mixstation_code)) {
        //         this.carPosition = JSON.parse(data);
        //     }
        // });
        // const searchBoxWrapper = document.getElementsByClassName('search-box-wrapper')[0];
        // const searchInput = searchBoxWrapper.getElementsByTagName('input')[0];
        // searchInput.setAttribute('placeholder', '地图位置');
        // 是否拉取所有场站
        this.allStation = this.$queryString(this.$route.meta.TableCode, 'type');
    },
    created() {
    },
    methods: {
        // 刷新车辆位置信息
        refreshMapCar() {
            const times = 5 * 60 * 1000;
            this.refreshCarTimer = setInterval(() => {
                this.getLocation();
            }, times);
        },
        // sdcfFn() {
        //     const json = {
        //         tenant_id: 'T200103000004',
        //         car_license: '鲁H05A18',
        //         longitude: 116.67309438103268,
        //         latitude: 35.442489034651494,
        //         irection: 30.78342226431211,
        //     };
        //     this.carPosition = json;
        // },
        // 地图加载完毕
        handleReady({ BMap, map }) {
            this.BMap = BMap;
            this.mapObj = map;
            // 开启鼠标滚动缩放地图
            map.enableScrollWheelZoom(true);

            if (this.isLargeScreen) {
                const mapStyle = {
                    style: 'midnight',
                };
                map.setMapStyle(mapStyle);
            }

            // 设置中心点、缩放级别
            this.mapCenter.lng = 116.404;
            this.mapCenter.lat = 39.915;
            this.zoom = 15;
            if (this.allStation === 'all') {
                this.getAllStation();
            } else {
                // 拉取用户场站
                this.getStationUser();
            }
            setTimeout(() => {
                const tenant_id = this.$takeTokenParameters('TenantId');
                this.groupName = tenant_id + '-PRODUCTION_DELIVERY';
                eventBus.$emit('signalrInvoke', 'AddToGroup', this.groupName);
            }, 1200);
        },
        // 切换icon
        checkoutIcon() {
            this.iconStatus = !this.iconStatus;
            this.searchInfo = '';
            if (this.iconStatus) {
                setTimeout(() => {
                    const searchBoxWrapper = document.getElementsByClassName('search-box-wrapper')[0];
                    const searchInput = searchBoxWrapper.getElementsByTagName('input')[0];
                    searchInput.setAttribute('placeholder', '地图位置');
                }, 50);
            }
        },
        // 秒转天
        SecondToDate(msd) {
            let time = msd;
            if (msd) {
                if (msd > 3600) {
                    time = '一小时以上';
                } else if (msd <= 3600 && msd >= 60 ) {
                    time = '大约 ' + parseInt(time / 60.0, 0) + '分钟 以后';
                } else {
                    time = '一分钟以内';
                }
            } else {
                msd = '';
            }
            return time;
        },
        // 设置车辆时间
        setCarTime() {
            this.settingWindowStatus = true;
            this.getCarInterval(0);
        },
        // 点标记
        switchWindow(tab, item, position) {
            this.loading = true;
            this.switchData = item;
            this.windows.visible = false;
            this.windows.position = position;
            const plate_number = item.plate_number || item.carName;
            this.getVehicleNo(plate_number, tab);
        },
        // 工程地址信息弹框
        showPojectAddressInfoWindow(marker) {
            // this.addressWindow.visible = false;
            // this.$nextTick(() => {
            //     this.addressWindow.visible = true;
            //     this.addressWindow.position = marker.position;
            // });
            this.$refs.projectAddressInfoWindow.projectAddressInfo(marker);

        },
        // 显示弹窗
        setWinodShow(event) {
            const setting = document.getElementsByClassName('setting-window-two')[0];
            setting.style.left = event.clientX - 200 + 'px';
            setting.style.top = event.clientY - 180 + 'px';
            this.getCarInterval();
            this.settingWindowStatusTwo = true;
        },
        // 搜索
        onSearchResult(pois) {
            if (pois.length > 0) {
                this.mapCenter = [pois[0].lng, pois[0].lat];
                this.zoom = 15;
            }
        },
        // 加载地图数据
        reLoadData() {
            this.getProjectStatus();
            this.getLocation();
        },
        // 获取车辆位置配置
        getCarInterval(status) {
            const setStatus = this.carInfo.plate_number ? this.carInfo.plate_number : status;
            this.$axios
                .get(`/interfaceApi/logistics/car_manager/get_car_interval?car_license=${setStatus}`)
                .then(res => {
                    this.setWholeTime = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 高德坐标转百度（传入经度、纬度）
        bd_encrypt(gg_lng, gg_lat) {
            const X_PI = Math.PI * 3000.0 / 180.0;
            const x = gg_lng;
            const y = gg_lat;
            const z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
            const theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
            const bd_lng = z * Math.cos(theta) + 0.0065;
            const bd_lat = z * Math.sin(theta) + 0.006;
            return {
                bd_lat: bd_lat,
                bd_lng: bd_lng,
            };
        },
        // 拉取车辆位置列表
        getLocation() {
            const carType = 0;
            this.$axios
                // eslint-disable-next-line max-len
                .get(`/interfaceApi/production/vehicleinfo/get_location?stationId=${this.currentStationId}&carType=${carType}&keywords=${this.searchInfo}`)
                .then(res => {
                    const markersArr = [];
                    this.markers = [];
                    if (res.length === 1) {
                        this.mapCenter = { lng: res[0].longitude, lat: res[0].latitude };
                    }
                    this.carStatistics = {
                        all: 0,
                        notWork: 0,
                        trans: 0,
                        back: 0,
                        onSite: 0,
                    };
                    res.forEach((item, index) => {
                        if (item.status !== 9) {
                            let markersObj = {};
                            if (item.longitude && item.latitude) {
                                const parseIcon = this.getIcon(item);
                                const obj = this.bd_encrypt(item.longitude, item.latitude);
                                // item.position = { lng: obj.bd_lng, lat: obj.bd_lat };
                                // 车辆信息
                                markersObj = {
                                    carName: item.plate_number,
                                    plate_small_number: item.plate_small_number,
                                    position: { lng: obj.bd_lng, lat: obj.bd_lat },
                                    numberOffset: [],
                                    // content: this.getContentHtml(item),
                                    icon: parseIcon.icon,
                                    size: parseIcon.size,
                                    offset: parseIcon.offset,
                                    events: {
                                        click: () => {
                                            this.switchWindow(index, item, markersObj.position);
                                        },
                                    },
                                    visible: false,
                                    type: '',
                                    status: item.status,
                                    rotation: item.direction,
                                };
                                if ([6].indexOf(item.status) > -1) {
                                    markersObj.type = 'trans';
                                    this.carStatistics.trans += 1;
                                } else if ([7, 73].indexOf(item.status) > -1) {
                                    markersObj.type = 'onSite';
                                    this.carStatistics.onSite += 1;
                                } else if ([77, 8].indexOf(item.status) > -1) {
                                    markersObj.type = 'back';
                                    this.carStatistics.back += 1;
                                } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                                    markersObj.type = 'notWork';
                                    this.carStatistics.notWork += 1;
                                }
                                markersArr.push(markersObj);
                            }
                        }
                    });
                    this.oldCars = JSON.parse(JSON.stringify(markersArr));
                    this.markers = markersArr;
                    this.carStatistics.all = markersArr.length;
                    this.createMarkesMapping();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 生成车辆icon点标记
        getIcon(item) {
            let icon = '';
            const size = { width: 0, height: 0 };
            const res = {
                icon: '',
                size: size,
                offset: { width: 0, height: 40 },
            };
            const obj = this.bd_encrypt(item.longitude, item.latitude);
            const pt = new this.BMap.Point(obj.bd_lng, obj.bd_lat);
            const isInarr = GeoUtils.isPointInPolygon(pt, this.stationEchoPolygon[0], this.BMap);
            if ([6].indexOf(item.status) > -1) {
                if (isInarr) {
                    icon = require('../../../assets/images/productionManagement/blue.png');
                    res.size = { width: 22, height: 38 };
                } else {
                    icon = require('../../../assets/images/productionManagement/blue.gif');
                    res.size = { width: 28, height: 45 };
                    res.offset = { width: 5, height: 40 };
                }
            } else if ([7, 73].indexOf(item.status) > -1) {
                if (isInarr) {
                    icon = require('../../../assets/images/productionManagement/green.png');
                    res.size = { width: 22, height: 38 };
                } else {
                    icon = require('../../../assets/images/productionManagement/green.gif');
                    res.size = { width: 28, height: 45 };
                    res.offset = { width: 5, height: 40 };
                }
            } else if ([77, 8].indexOf(item.status) > -1) {
                if (isInarr) {
                    icon = require('../../../assets/images/productionManagement/yellow.png');
                    res.size = { width: 22, height: 38 };
                } else {
                    icon = require('../../../assets/images/productionManagement/yellow.gif');
                    res.size = { width: 28, height: 45 };
                    res.offset = { width: 5, height: 40 };
                }
            } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                if (isInarr) {
                    icon = require('../../../assets/images/productionManagement/red.png');
                    res.size = { width: 22, height: 38 };
                } else {
                    icon = require('../../../assets/images/productionManagement/red.gif');
                    res.size = { width: 28, height: 45 };
                    res.offset = { width: 5, height: 40 };
                }

            }
            res.icon = icon;
            return res;
        },
        // 获取车辆信息
        getVehicleNo(carName) {
            this.$axios
                .get(`/interfaceApi/production/vehicleinfo/get_vehicle_no?plate_number=${carName}`)
                .then(res => {
                    this.carInfo = res;
                    this.windows.visible = true;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error);
                });
        },
        // 设置车辆回写时间间隔
        setCarInterval(status) {
            const setStatus = this.carInfo.plate_number ? this.carInfo.plate_number : status;
            const regPos = /^[0-9]+.?[0-9]*$/;
            const time = Number(this.setWholeTime);
            if (regPos.test(time)) {
                this.$axios
                    .put(`/interfaceApi/logistics/car_manager/set_car_interval/${setStatus}/${time}`)
                    .then(() => {
                        this.$message.success('操作成功');
                        this.settingWindowStatus = false;
                        this.settingWindowStatusTwo = false;
                        this.setWholeTime = 0;
                        this.setStatus = 0;
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message.error('请输入正确的时间');
            }
        },
        // 获取当天有任务的工地
        getProjectStatus() {
            const currentDate = util.currentTimeFormat('yyyy-MM-dd');
            this.$axios
                .get('/interfaceApi/production/producttask/get_project_status?date=' + currentDate + '&stationCode=' + this.currentStationId)
                .then(res => {
                    if (res) {
                        this.projectsAddress = res;
                        this.addProjectFence(res);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 添加所有工地marker和围栏数据
        addProjectFence(res) {
            let all = 0; let notWork = 0; let notFinished = 0; let trans = 0;
            this.fenceData = [];
            this.projectsAddressMarkers = [];
            // 清空工地围栏
            if (this.projectEchoPolygon.length > 0) {
                this.mapObj.removeOverlay(this.projectEchoPolygon);
            }
            this.projectCircles = [];
            // 工地按状态分类
            res.map(item => {
                item.longitude = item.project_longitude;
                item.latitude = item.project_latitude;
                // const obj = new this.BMap.Point(arr.bd_lng, arr.bd_lat);
                if (item.project_longitude && item.project_latitude) {
                    const arr = this.bd_encrypt(item.longitude, item.latitude);
                    const markersObj = {
                        position: { lng: arr.bd_lng, lat: arr.bd_lat },
                        icon: require('../../../assets/images/productionManagement/flag_blue.png'),
                        size: { width: 26, height: 24 },
                        visible: false,
                        status: item.status,
                        type: '',
                        project_code: item.project_code,
                        project_name: item.project_name,
                    };
                    if (item.status === 1) {
                        markersObj.icon = require('../../../assets/images/productionManagement/flag_red.png');
                        markersObj.type = 'notWork';
                        item.fenceType = 'notWork';
                    } else if (item.status === 3) {
                        markersObj.icon = require('../../../assets/images/productionManagement/flag_green.png');
                        markersObj.type = 'trans';
                        item.fenceType = 'trans';
                    } else {
                        markersObj.type = 'notFinished';
                        item.fenceType = 'notFinished';
                    }
                    const width = -(item.project_name.length * 11) / 2;
                    markersObj.offset = { width: width, height: 30 };
                    this.projectsAddressMarkers.push(markersObj);
                    item.longitude = arr.bd_lng;
                    item.latitude = arr.bd_lat;
                    this.fenceData.push(item);
                }
                if (item.status === 1) {
                    notWork += 1;
                } else if (item.status === 3) {
                    trans += 1;
                } else {
                    notFinished += 1;
                }
            });
            this.oldProjects = JSON.parse(JSON.stringify(this.projectsAddressMarkers));
            all = res.length;
            this.projectsStatistics.all = all;
            this.projectsStatistics.notWork = notWork;
            this.projectsStatistics.notFinished = notFinished;
            this.projectsStatistics.trans = trans;
            // 所有工地画围栏
            this.drawStationFence(res, 'project');
        },
        // 展示蚂蚁线
        showAntLine() {
            let stationCodeStr = '';
            if (this.stationList.length > 0) {
                stationCodeStr = this.stationList[this.activeStation].station_id;
            }
            this.$toast({
                title: false,
                type: 'eject',
                t_url: 'productionManagement/logisticsMonitoring/antLine',
                viewPosition: 'view',
                extr: { closeBtn: {}, stationCode: stationCodeStr },
            });
        },
        // 拉取用户关联场站
        getStationUser() {
            const Uid = this.$takeTokenParameters('Uid');
            this.$axios
                .get('/interfaceApi/baseinfo/stationmanger/get_station_user?userId=' + Uid + '&roleCode=data_filtering')
                .then(res => {
                    if (res.length > 0) {
                        let res1 = [];
                        res1 = res.map(item => {
                            if (item.longitude && item.latitude) {
                                const arr = this.bd_encrypt(item.longitude, item.latitude);
                                item.longitude = arr.bd_lng;
                                item.latitude = arr.bd_lat;
                            }
                            return item;
                        });
                        this.stationList = res1;
                        this.currentStationId = res1[0].station_id;
                        this.reLoadData();
                        this.mapCenter = { lng: res1[0].longitude, lat: res1[0].latitude };
                        this.drawStationFence([res1[0]], 'station');
                    }
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        // 拉取所有场站
        getAllStation() {
            const CompanyId = this.$takeTokenParameters('CompanyId');
            const BranchId = this.$takeTokenParameters('BranchId');
            this.$axios
                .get('/interfaceApi/baseinfo/stationmanger/get_station_list?companyId=' + CompanyId + '&branchId=' + BranchId)
                .then(res => {
                    if (res.length > 0) {
                        let res1 = [];
                        res1 = res.map(item => {
                            if (item.longitude && item.latitude) {
                                const arr = this.bd_encrypt(item.longitude, item.latitude);
                                item.longitude = arr.bd_lng;
                                item.latitude = arr.bd_lat;
                            }
                            return item;
                        });
                        this.stationList = res1;
                        this.currentStationId = res1[0].station_id;
                        this.reLoadData();
                        this.mapCenter = [res1[0].longitude, res1[0].latitude];
                        this.drawStationFence([res1[0]], 'station');
                    }
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        // 顶部场站筛选
        changeStation() {
            this.$emit('siloSwitchingStation', this.currentStationId);
            let selectStation = {};
            this.stationList.forEach((item, index) => {
                if (item.station_id === this.currentStationId) {
                    selectStation = item;
                    this.activeStation = index;
                }
            });
            this.mapCenter = { lng: selectStation.longitude, lat: selectStation.latitude };
            this.reLoadData();
            // 画当前场站围栏
            if (this.stationEchoPolygon.length > 0) {
                this.mapObj.removeOverlay(this.stationEchoPolygon);
            }
            this.stationCircles = [];
            this.timer = new Date().getTime();
            this.drawStationFence([selectStation], 'station');
        },
        // 画围栏或一公里圆圈
        drawStationFence(data, type) {
            if (data) {
                data.map(item => {
                    if (item.fence_point) {
                        const arr = item.fence_point.split('|');
                        const polygonPath = [];
                        arr.map(item => {
                            const lngLat = item.split(',');
                            const arr = this.bd_encrypt(lngLat[0], lngLat[1]);
                            const obj = new this.BMap.Point(arr.bd_lng, arr.bd_lat);
                            polygonPath.push(obj);
                        });
                        // 创建面覆盖物
                        // eslint-disable-next-line no-undef
                        const echoPolygon = new this.BMap.Polygon(polygonPath, {
                            fillColor: '#F7B500',
                            strokeColor: '#F7B500',
                            fillOpacity: 0.3,
                            strokeWeight: 2,
                        });
                        if (type === 'project') {
                            this.projectEchoPolygon.push(echoPolygon);
                        } else {
                            this.stationEchoPolygon.push(echoPolygon);
                        }
                        this.mapObj.addOverlay(echoPolygon);
                    } else if (item.longitude && item.latitude) {
                        if (type === 'project') {
                            // const arr = this.bd_encrypt(item.longitude, item.latitude);
                            this.projectCircles.push(
                                {
                                    center: { lng: item.longitude, lat: item.latitude },
                                    radius: 1000,
                                    strokeColor: '#e87f8d', // 线条颜色
                                    strokeOpacity: 0.8, // 轮廓线透明度
                                    strokeWeight: 2, // 轮廓线宽度
                                    fillColor: '#e87f8d', // 圆形填充颜色
                                    fillOpacity: 0.3, // 圆形填充透明度
                                    strokeStyle: 'dashed', // 轮廓线样式，实线:solid，虚线:dashed
                                },
                            );
                        } else {
                            // const arr = this.bd_encrypt(item.longitude, item.latitude);
                            this.stationCircles.push(
                                {
                                    center: { lng: item.longitude, lat: item.latitude },
                                    radius: 1000,
                                    strokeColor: '#e87f8d', // 线条颜色
                                    strokeOpacity: 0.8, // 轮廓线透明度
                                    strokeWeight: 2, // 轮廓线宽度
                                    fillColor: '#e87f8d', // 圆形填充颜色
                                    fillOpacity: 0.3, // 圆形填充透明度
                                    strokeStyle: 'dashed', // 轮廓线样式，实线:solid，虚线:dashed
                                },
                            );
                        }
                    }
                });
            }
        },
        // 点击右侧工地筛选
        handleProjectsClick(num) {
            // 选中的工地类型
            this.activeProject = num;
            let Markers = [];
            this.projectsAddressMarkers = this.oldProjects;
            // 清空工地围栏
            if (this.projectEchoPolygon.length > 0) {
                this.mapObj.removeOverlay(this.projectEchoPolygon);
            }
            this.projectCircles = [];
            let fenceData = [];
            switch (this.activeProject) {
                case 2:
                    this.projectsAddressMarkers.map(item => {
                        if (item.type === 'notwork') {
                            Markers.push(item);
                        }
                    });
                    this.fenceData.map(item => {
                        if (item.fenceType === 'notwork') {
                            fenceData.push(item);
                        }
                    });
                    break;
                case 3:
                    this.projectsAddressMarkers.map(item => {
                        if (item.type === 'notFinished') {
                            Markers.push(item);
                        }
                    });
                    this.fenceData.map(item => {
                        if (item.fenceType === 'notFinished') {
                            fenceData.push(item);
                        }
                    });
                    break;
                case 4:
                    this.projectsAddressMarkers.map(item => {
                        if (item.type === 'trans') {
                            Markers.push(item);
                        }
                    });
                    this.fenceData.map(item => {
                        if (item.fenceType === 'trans') {
                            fenceData.push(item);
                        }
                    });
                    break;
                default:
                    // 所有工地
                    fenceData = this.fenceData;
                    Markers = this.projectsAddressMarkers;
            }
            // 工地围栏
            this.drawStationFence(fenceData, 'project');
            this.projectsAddressMarkers = Markers;
        },
        // 点击右侧车辆筛选
        handleCarClick(num) {
            this.markers = this.oldCars;
            let data = [];
            switch (num) {
                case 2:
                    this.oldCars.map(item => {
                        if (item.type === 'trans') {
                            data.push(item);
                        }
                    });
                    break;
                case 3:
                    this.oldCars.map(item => {
                        if (item.type === 'back') {
                            data.push(item);
                        }
                    });
                    break;
                case 4:
                    this.oldCars.map(item => {
                        if (item.type === 'notWork') {
                            data.push(item);
                        }
                    });
                    break;
                case 5:
                    this.oldCars.map(item => {
                        if (item.type === 'onSite') {
                            data.push(item);
                        }
                    });
                    break;
                default:
                    data = this.oldCars;
            }
            this.markers = data;
            this.createMarkesMapping();
        },
        // 生成makers 映射，方便快速定位  {key: 车牌号 ,value：车辆信息对象}
        createMarkesMapping() {
            const markersMapping = {};
            this.markers.forEach(item => {
                markersMapping[item.carName] = item;
            });
            this.markersMapping = markersMapping;
        },
        // 验证是否是当前租户和场站下的数据
        validData(tenant_id, mixstation_code) {
            const userTenantId = this.$takeTokenParameters('TenantId');
            const curMixStationCode = this.currentStationId;
            if (tenant_id === userTenantId && mixstation_code === curMixStationCode) {
                return true;
            }
            return false;
        },
    },

};
</script>
<style lang="stylus">
@import "./../css/vehicleLogisticsMonitoringBd.styl";
</style>
