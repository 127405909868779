<!--
 * @Author: DY
 * @Date: 2020-05-27 14:52:13
 * @LastEditTime: 2021-06-29 11:41:14
 * @LastEditors: Please set LastEditors
 * @Description: 企业大屏
 * @FilePath: \JT_Web_test\src\views\enterpriseScreen\index.vue
-->
<template>
    <div class="enterpriseScreen">
        <!-- <back></back> -->
        <!-- <el-badge class="el_left fallback">
            <el-button
                size="small"
                class="iconfont iconjiantouzuo"
                @click="fallback"
            >
                返回
            </el-button>
        </el-badge> -->
        <header>
            <span @click="centerDialogVisible = true">{{ title }}</span>
        </header>
        <section>
            <div class="el_left content_l">
                <div class="dailyData">
                    <div class="title">
                        <h4>每日数据</h4>
                    </div>
                    <div class="header">
                        <div class="el_left time iconfont iconbelow-s">
                            <el-date-picker
                                v-model="dailyDataTime"
                                type="date"
                                placeholder="选择日期"
                                value-format="yyyy-MM-dd"
                                @change="switchingDate"
                            >
                            </el-date-picker>
                        </div>
                        <div class="el_right type">
                            <div class="chartHead">
                                <el-select
                                    v-model="defaultStation"
                                    placeholder="请选择"
                                    @change="switchSites('day')"
                                >
                                    <el-option
                                        v-for="item in stationData"
                                        :key="item.station_id"
                                        :label="item.station_name"
                                        :value="item.station_id"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="total">
                        <div>
                            <h4>总任务：</h4>
                            <p class="scrollNumbers scrollNumbers2" id="totalTask"></p>
                        </div>
                        <div>
                            <h4>任务方量：</h4>
                            <p class="scrollNumbers scrollNumbers1" id="taskVolume"></p>
                        </div>
                        <div>
                            <h4>发货方量：</h4>
                            <p class="scrollNumbers scrollNumbers1" id="productionVolume"></p>
                        </div>
                        <div>
                            <h4>完成率(%)：</h4>
                            <p class="scrollNumbers scrollNumbers1" id="completionRate"></p>
                        </div>
                        <div>
                            <h4>已发车：</h4>
                            <p class="scrollNumbers scrollNumbers3" id="departure"></p>
                        </div>
                    </div>
                    <div class="chartTitle">
                        <span>生产情况</span>
                    </div>
                    <div class="charts" id="productionSituation"></div>
                    <div class="chartTitle">
                        <span>发货情况</span>
                    </div>
                    <div class="charts" id="delivery"></div>
                </div>
                <div class="weeklySales">
                    <div class="title">
                        <h4>近一周销量</h4>
                    </div>
                    <div class="charts" id="weeklySalesCharts"></div>
                </div>
            </div>
            <div class="el_left content_r">
                <div class="map">
                    <transportMap ref="mapBd" :is-large-screen="true"></transportMap>
                </div>
                <div class="stock">
                    <div class="title">
                        <h4>库存情况</h4>
                    </div>
                    <div class="materialScience">
                        <el-button
                            size="small"
                            v-if="isScroll"
                            class="btn_l iconfont iconjiantouzuo"
                            @click="scrollLeft"
                        >
                        </el-button>
                        <div class="materialScienceBox">
                            <ul :style="{width: materialScienceWidth +'px'}">
                                <template v-for="(item,index) in stock_fictitious">
                                    <li :key="index">
                                        <h4>{{ item.material_name }}</h4>
                                        <p class="orange" :class="{'green': item.type_dic_code == 204}">
                                            {{ item.volume_theory }}吨
                                        </p>
                                    </li>
                                </template>
                            </ul>
                        </div>
                        <el-button
                            size="small"
                            v-if="isScroll"
                            class="btn_r iconfont iconjiantouyou"
                            @click="scrollRight"
                        >
                        </el-button>
                    </div>
                    <!-- <div class="charts" id="aaa"></div> -->
                    <div class="chartBox">
                        <div class="productionLineCharts productionLine_l">
                            <div class="chartHead">
                                <el-select
                                    v-model="productionLine_l"
                                    placeholder="请选择"
                                    @change="switchProductionLine('productionLine_l')"
                                >
                                    <el-option
                                        v-for="item in productionLine"
                                        :key="item.line_no"
                                        :label="item.line_name"
                                        :value="item.line_no"
                                        :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="charts">
                                <div class="coneColumn"></div>
                                <div class="columnChart"></div>
                            </div>
                        </div>
                        <div class="productionLineCharts productionLine_r">
                            <div class="chartHead">
                                <el-select
                                    v-model="productionLine_r"
                                    placeholder="请选择"
                                    @change="switchProductionLine('productionLine_r')"
                                >
                                    <el-option
                                        v-for="item in productionLine"
                                        :key="item.line_no"
                                        :label="item.line_name"
                                        :value="item.line_no"
                                        :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="charts">
                                <div class="coneColumn"></div>
                                <div class="columnChart"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import './css/enterpriseScreen.styl';
// import back from './../EBCHome/back';
import {
    // drawPictorialBar2,
    stereogramBar,
    drawBar,
    lineColumn,
} from './../../assets/js/charts';
import { DigitRoll } from './../../assets/js/charts/digitRoll.js';
// import { setTimeout } from 'timers';

// 地图组件
import transportMap from './../productionManagement/logisticsMonitoring/vehicleLogisticsMonitoringBd';

export default {
    // 领导驾驶舱
    name: 'leading-cockpit',
    components: {
        // back,
        transportMap,
    },
    props: {},
    data() {
        return {
            title: '',
            // 父页面路由
            parentPageName: '',
            // 每日数据时间
            dailyDataTime: '2020-06-04',
            // 当前日期-获取近一周销量
            currentDate: '2020-06-04',
            // 场站数据
            stationData: [],
            // 默认场站
            defaultStation: '',
            // 库存情况-虚拟料仓（砂、石）
            stock_fictitious: [],
            // 库存情况-虚拟料仓宽度
            materialScienceWidth: '',
            // 库存情况-虚拟料仓容器宽度
            materialScienceBoxWidth: '',
            // 库存情况-虚拟料仓是否可滚动
            isScroll: false,
            // 库存情况-物理料仓
            stock_physics: [],
            // 场站数据
            productionLine: [],
            // 已选站点1
            productionLine_l: '',
            // 已选站点2
            productionLine_r: '',
            // 可选场站
            optionalProductionLine: [],
            // 默认刷新时间
            refreshTime: 5000,
            // 是否可刷新
            isRefresh: false,
        };
    },
    computed: {},
    watch: {
        // productionLine_l(newVal) {
        //     this.productionLine_l_chart(newVal);
        // },
        // productionLine_r(newVal) {
        //     this.productionLine_r_chart(newVal);
        // },
    },
    beforecreate() {},
    created() {
        const tenantid = this.$takeTokenParameters('TenantId');
        if (tenantid === 'T200103000004') {
            this.title = '济宁中联EBC展示平台';
        } else if (tenantid === 'T200103000002') {
            this.title = '青州中联EBC展示平台';
        } else if (tenantid === 'T210104000001') {
            this.title = '滁州中联EBC展示平台';
        } else if (tenantid === 'T210125000001') {
            this.title = '日照中联EBC展示平台';
        } else if (tenantid === 'T210222000002') {
            this.title = '青岛中联EBC展示平台';
        } else if (tenantid === 'T210308000001') {
            this.title = '烟台中联EBC展示平台';
        } else if (tenantid === 'T210315000001') {
            this.title = '洛阳中联EBC展示平台';
        } else if (tenantid === 'T210317000001') {
            this.title = '宁夏建材EBC平台';
        } else {
            this.title = '中联EBC展示平台';
        }

        // 父页面路由
        this.parentPageName = this.$route.query.routeName;
        // 每日数据时间
        this.dailyDataTime = this.getNowFormatDate();
        // 当前日期-获取近一周销量
        this.currentDate = this.getNowFormatDate();
        // 获取站点数据
        this.getStationData();
        // 处理signlar消息
        this.handleSignlarMsg();
    },
    mounted() {
        // 总任务
        this.electronicNumber('#totalTask', 0);
        // 任务方量
        this.electronicNumber('#taskVolume', 0);
        // 发货方量
        this.electronicNumber('#productionVolume', 0);
        // 完成率
        this.electronicNumber('#completionRate', 0);
        // 已发车
        this.electronicNumber('#departure', 0);
    },
    destroyed() { },
    methods: {
        // 返回上一页
        fallback() {
            this.$router.replace({ path: this.parentPageName });
        },
        // 切换日期
        switchingDate() {
            if (this.dailyDataTime !== this.currentDate) {
                this.isRefresh = false;
            }
            // 获取总任务数
            this.getTasksNumber();
        },
        // 切换站点
        switchSites() {
            // 获取总任务数
            this.getTasksNumber();
            // 获取每日发货情况
            this.getDelivery();
            // 获取近一周销量
            this.getWeeklySales();
            // 获取库存情况-虚拟料仓（砂、石）
            this.getStock_fictitious();
            // 获取库存情况-物理料仓
            this.getStock_physics();
            // 获取库存情况-物理料仓生产线数据
            setTimeout(() => {
                this.getProductionLine();
            }, 1000);
            this.$refs.mapBd.currentStationId = this.defaultStation;
            this.$refs.mapBd.changeStation();
        },
        // 获取站点数据
        getStationData() {
            this.$axios
                .get('/interfaceApi/report/largescreen3/czxx' )
                .then(res => {
                    if (res) {
                        this.stationData = res;
                        this.defaultStation = res[0].station_id;
                        // 获取总任务数
                        this.getTasksNumber();
                        // 获取每日发货情况
                        this.getDelivery();
                        // 获取近一周销量
                        this.getWeeklySales();
                        // 获取库存情况-虚拟料仓（砂、石）
                        this.getStock_fictitious();
                        // 获取库存情况-物理料仓
                        this.getStock_physics();
                        // 获取库存情况-物理料仓生产线数据
                        setTimeout(() => {
                            this.getProductionLine();
                        }, 1000);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取总任务数
        getTasksNumber() {
            this.$axios
                .get(`/interfaceApi/report/tjfx/mrsj/new/tjs?datetime=${this.dailyDataTime}&station_id=${this.defaultStation}`)
                .then(res => {
                    if (res) {
                        // 总任务数
                        this.electronicNumber('#totalTask', res.rws);
                        // 任务方量
                        this.electronicNumber('#taskVolume', res.rwfl);
                        // 发货方量
                        this.electronicNumber('#productionVolume', res.fhfl);
                        // 完成率
                        this.electronicNumber('#completionRate', res.wcl);
                        // 已发车
                        this.electronicNumber('#departure', res.yfcs);
                        // 获取每日生产情况
                        this.getProductionSituation();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取每日生产情况
        getProductionSituation() {
            this.$axios
                .get(`/interfaceApi/report/largescreen3/mrsj/scqk/${this.defaultStation}?datetime=${this.dailyDataTime}`)
                .then(res => {
                    if (res) {
                        this.productionSituationHandle(res);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 每日生产情况数据处理
        productionSituationHandle(data) {
            const chartData = {
                xAxis: {
                    data: [],
                },
                yAxis: {
                    name: '(方)',
                    nameTextStyle: [0, 0, -7, 0],
                },
                series: [
                    {
                        name: '任务方量',
                        barWidth: 24,
                        color: [ '#20E5FF', '#2F7EF2'],
                        data: [],
                    }, {
                        name: '生产方量',
                        barWidth: 24,
                        color: ['#FFC452', '#FB7D3C' ],
                        data: [],
                    },
                ],
            };
            data.forEach(element => {
                chartData.xAxis.data.push(element.name);
                chartData.series[0].data.push(element.rwfl);
                chartData.series[1].data.push(element.scfl);
            });
            // 每日生产情况
            drawBar('productionSituation', chartData);
        },
        // 获取每日发货情况
        getDelivery() {
            this.$axios
                .get(`/interfaceApi/report/largescreen3/mrsj/fhqk/${this.defaultStation}?datetime=${this.dailyDataTime}`)
                .then(res => {
                    if (res) {
                        this.deliveryHandle(res);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 每日发货情况数据处理
        deliveryHandle(data) {
            const chartData = {
                xAxis: {
                    data: [],
                },
                yAxis: [
                    {
                        name: '(次)',
                        nameTextStyle: [0, 0, -7, 0],
                    }, {
                        name: '(方)',
                        nameTextStyle: [0, 0, -7, 0],
                    },
                ],
                series: [
                    {
                        type: 'bar',
                        name: '发货方量',
                        barWidth: 32,
                        color: [ '#20E5FF', '#2F7EF2'],
                        data: [],
                    }, {
                        type: 'line',
                        name: '已发车次',
                        color: [ '#60BB41'],
                        data: [],
                    },
                ],
            };
            data.forEach(element => {
                chartData.xAxis.data.push(element.name);
                chartData.series[0].data.push(element.fhfl);
                chartData.series[1].data.push(element.yfcs);
            });
            // 每日发货情况
            lineColumn('delivery', chartData);

        },
        // 获取近一周销量
        getWeeklySales() {
            this.$axios
                .get(`/interfaceApi/report/largescreen3/xlqk/jyz/${this.defaultStation}?datetime=${this.currentDate}`)
                .then(res => {
                    if (res) {
                        this.weeklySalesHandl(res);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 周销量数据处理
        weeklySalesHandl(data) {
            const chartData = {
                xAxis: {
                    data: [],
                },
                yAxis: [
                    {
                        name: '(方)',
                        nameTextStyle: [0, 0, -7, 0],
                    }, {
                        name: '(元)',
                        nameTextStyle: [0, 0, -7, 0],
                    },
                ],
                series: [
                    {
                        type: 'bar',
                        name: '销量',
                        barWidth: 32,
                        color: [ '#FCEF20', '#FEBD29'],
                        data: [],
                    }, {
                        type: 'line',
                        name: '总金额',
                        color: [ '#FC5D52'],
                        data: [],
                    },
                ],
            };
            data.forEach(element => {
                chartData.xAxis.data.push(element.rq);
                chartData.series[0].data.push(element.zxl);
                chartData.series[1].data.push(element.zje);
            });
            // 近一周销量
            lineColumn('weeklySalesCharts', chartData);
        },
        // 获取库存情况-虚拟料仓（砂、石）
        getStock_fictitious() {
            this.$axios
                .get(`/interfaceApi/report/largescreen3/kcqk/xnlc/${this.defaultStation}`)
                .then(res => {
                    if (res) {
                        this.stock_fictitious = res;
                        this.materialScienceWidth = res.length * 130 - 20;
                        this.materialScienceBoxWidth = this.$('.materialScienceBox').width();
                        if (this.materialScienceWidth > this.materialScienceBoxWidth) {
                            this.isScroll = true;
                        }
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 虚拟料仓左移动
        scrollLeft() {
            if (this.isScroll) {
                this.$('.materialScienceBox ul').css('left', 0);
            }
        },
        // 虚拟料仓右移动
        scrollRight() {
            if (this.isScroll) {
                const newLeft = this.materialScienceWidth - this.materialScienceBoxWidth;
                this.$('.materialScienceBox ul').css('left', -newLeft);
            }
        },
        // 获取库存情况-物理料仓生产线数据
        async getProductionLine() {
            await this.$axios
                .get(`/interfaceApi/report/largescreen3/scx/${this.defaultStation}`)
                .then(res => {
                    if (res && res.length > 0) {
                        this.productionLine = res;
                        this.productionLine_l = res[0].line_no;
                        this.productionLine_l_chart(this.productionLine_l);
                        this.productionLine_r = res[1].line_no;
                        this.productionLine_r_chart(this.productionLine_r);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 切换物理料仓生产线数据
        switchProductionLine(obj) {
            if (obj === 'productionLine_l') {
                this.productionLine_l_chart(this.productionLine_l);
            } else if (obj === 'productionLine_r') {
                this.productionLine_r_chart(this.productionLine_r);
            }
        },
        // 加载左边柱图
        productionLine_l_chart(newVal) {
            this.$('.productionLine_l .coneColumn').html('');
            this.$('.productionLine_l .columnChart').html('');
            this.stock_physics.forEach(item => {
                if (item.title === newVal) {
                    item.sur_name.forEach((k, i) => {
                        const newDom = `<div class="chart" id="materielChar_l${i}"></div>`;
                        if (k.type_dic_code !== '208') {
                            this.$('.productionLine_l .coneColumn').append(newDom);
                        } else {
                            this.$('.productionLine_l .columnChart').append(newDom);
                        }
                        stereogramBar('materielChar_l' + i + '', k);
                    });
                }
            });
            this.$forceUpdate();
        },
        // 加载右边柱图
        productionLine_r_chart(newVal) {
            this.$('.productionLine_r .coneColumn').html('');
            this.$('.productionLine_r .columnChart').html('');
            this.stock_physics.forEach(item => {
                if (item.title === newVal) {
                    item.sur_name.forEach((k, i) => {
                        const newDom = `<div class="chart" id="materielChar_r${i}"></div>`;
                        if (k.type_dic_code !== '208') {
                            this.$('.productionLine_r .coneColumn').append(newDom);
                        } else {
                            this.$('.productionLine_r .columnChart').append(newDom);
                        }
                        stereogramBar('materielChar_r' + i + '', k);
                    });
                }
            });
            this.$forceUpdate();
        },
        // 获取库存情况-物理料仓
        async getStock_physics() {
            await this.$axios
                .get(`/interfaceApi/report/largescreen3/kcqk/wllc/${this.defaultStation}`)
                .then(res => {
                    if (res) {
                        this.stock_physics = [];
                        this.getStock_physicsHandl(res);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取库存情况-物理料仓数据处理
        getStock_physicsHandl(data) {
            let flag = 0;
            let wdy = {
                title: '',
                sur_name: '',
            };
            for (let i = 0; i < data.length; i++) {
                let az = '';
                for (let j = 0; j < this.stock_physics.length; j++) {
                    if (this.stock_physics[j].title === data[i].team_id) {
                        flag = 1;
                        az = j;
                        break;
                    }
                }
                if (flag === 1) {
                    const ab = this.stock_physics[az];
                    ab.sur_name.push(data[i]);
                    flag = 0;

                } else if (flag === 0) {
                    wdy = {};
                    wdy.title = data[i].team_id;
                    wdy.sur_name = new Array();
                    wdy.sur_name.push(data[i]);
                    this.stock_physics.push(wdy);
                }
            }
        },
        // 格式化电子数字
        electronicNumber(obj, nub) {
            if (!nub) {
                nub = 0.0;
            }
            new DigitRoll({
                container: obj,
            }).roll(nub);
        },
        // 获取当前日期
        getNowFormatDate() {
            const date = new Date();
            const seperator1 = '-';
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            let strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = '0' + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = '0' + strDate;
            }
            const currentdate = year + seperator1 + month + seperator1 + strDate;
            return currentdate;
        },
        // 任务下发更新
        taskDistributionUpdate() {
            // 总任务数
            this.getTasksNumber();
        },
        // 排车完成更新
        platoonCompleteUpdate() {
            // 总任务数
            this.getTasksNumber();
            // 获取每日生产情况
            this.getProductionSituation();
            // 获取每日发货情况
            this.getDelivery();
            // 获取近一周销量
            this.getWeeklySales();
            // 获取库存情况-虚拟料仓（砂、石）
            this.getStock_fictitious();
            // 获取库存情况-物理料仓生产线数据
            this.getProductionLine();
        },
        // 消息更新
        messageUpdate(data, type) {
            const _that = this;
            const thisType = type;
            const tenant_id = this.$takeTokenParameters('TenantId');
            if (
                data.tenant_id === tenant_id
                && data.station_id === this.defaultStation
                && this.dailyDataTime === this.currentDate
                && this.isRefresh === false
            ) {
                _that.isRefresh = true;
                setTimeout(() => {
                    if (_that.isRefresh === true) {
                        _that.isRefresh = false;
                        if (thisType === 1) {
                            _that.taskDistributionUpdate();
                        } else if (thisType === 2) {
                            _that.platoonCompleteUpdate();
                        }
                    }
                }, this.refreshTime);
            }
        },
        // 处理signlar消息
        handleSignlarMsg() {
            /* 连接signlar */
            const _that = this;
            const token = sessionStorage.getItem('token').split(' ')[1];
            const signalR = require('../../../public/signalr/signalr.min');
            const connection = new signalR.HubConnectionBuilder().withUrl('/interfaceApi/signalr/signalRHubService', {
                accessTokenFactory: () => token,
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            }).build();
            // 生产任务下发消息
            connection.on('TaskDistributionSignalRMessage', function (data) {
                _that.messageUpdate(data, 1);
            });
            // 派车单完成消息
            connection.on('ProductPcbCompleteSignalRMessage', function (data) {
                _that.messageUpdate(data, 2);
            });
            connection.start().then(function () {
                console.log('连接signlar成功！');
            }).catch(function (err) {
                return console.error(err.toString());
            });
        },
    },
};
</script>
<style lang="stylus" scoped></style>
